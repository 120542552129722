<template>
    <app-header/>
        <div class="container-fluid p-0">
            <div class="d-flex topspace-90" dir="rtl">
                <app-side-bar/>
                <Suspense>
                    <template #default>
                        <app-content :content_id='content_id'/>
                    </template>
                    <template #fallback>
                        <app-loading/>
                    </template>
                </Suspense>
            </div>
        </div>
    <app-footer/>
</template>

<script>
import AppFooter from '../../components/layout/AppFooter.vue'
import AppHeader from '../../components/layout/AppHeader.vue'
import AppSideBar from '../../components/layout/AppSideBar.vue'
import AppLoading from '../../components/Spinners/AppLoading.vue'
import { defineAsyncComponent, Suspense } from "vue";
const AppContent = defineAsyncComponent(() =>
  import("../../components/Pages/AppContent.vue")
);
export default {
  props: {
    content_id: String
  },
  name: 'Contents',
  components: { AppHeader, AppSideBar, AppContent, AppFooter,Suspense,AppLoading },

}
</script>

<style>

</style>